<template>
  <div class="agree-page">
    <div class="agree_main">
      <div class="agree_pos">
        <router-link to="/personage/AuditReport">成交报告 </router-link>
        <span> > </span>
        <span>{{
          details.status == 0 ? "审核中" : details.status == 1 ? "审核成功" : "审核失败"
        }}</span>
        <span> > </span>
        <span>{{ type == 1 ? "我的审核" : "审核他人" }}</span>
        <span> > </span>
        <span class="agree_a">详情</span>
      </div>
      <!--agree-body start-->
      <div class="record-body">
        <ul class="personage_ul">
          <li>
            <div class="personage_ul_img m-r-20">
              <img v-if="details.fang" :src="details.fang.fang_fengmian_image" alt="" />
              <div class="personage_img_marking">
                {{ details.fang ? details.fang.fang_level : '' }}
              </div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="t-flex">
                <div class="personage_ul_name">{{ details.fang ? details.fang.name : '' }}</div>
              </div>
              <div class="personage_ul_title">
                {{ details.fang ? details.fang.fang_xing : '' }} |
                {{ details.fang ? details.fang.fang_mianji : '' }}m² | {{ details.fang ? details.fang.user_name : '' }}
              </div>
              <div class="personage_ul_tags" v-if="details.fang">
                <span class="tag" v-for="(item, index) in details.fang.fang_biaoqian" :key="index">{{ item }}</span>
              </div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <div class="personage_ul_price_left m-r-20">
                    <span>{{ details.fang ? details.fang.can_price : '' }}元/月</span>
                    <!-- <img
                      v-if="details.fang_price_status == 1"
                      src="../../assets/image/personage/up2.png"
                      alt=""
                    />
                    <img
                      v-else
                      src="../../assets/image/personage/upb.png"
                      alt=""
                    /> -->
                  </div>
                  <!-- <div class="personage_ul_price_right">
                    <span>{{ details.fang.zong_price }}元/m²</span>
                    <img src="../../assets/image/personage/up1.png" alt="" />
                  </div> -->
                </div>
                <div class="flex" v-if="details.status == 0">
                  <template v-if="type == 1">
                    <el-button class="audit-list-btn red" @click="cancelReport">取消</el-button>
                  </template>
                  <template v-else>
                    <el-button @click="changeCheck(1)" class="audit-list-btn red m-r-10">拒绝</el-button>
                    <el-button @click="changeCheck(2)" class="audit-list-btn blue">同意</el-button>
                  </template>
                </div>
                <div class="" v-if="details.status == 2">
                  <el-button @click="changeCheck(3)" class="audit-list-btn blue">重新上传</el-button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--agree_detail start-->
        <div class="agree_detail">
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">客户信息</div>
            <div class="agree_detail_item">
              <div class="li w33">
                <span class="color-6">客户姓名：</span> {{ details.kehu_name }}
              </div>
              <div class="li w33">
                <span class="color-6"> 电话：</span>{{ details.kehu_mobile }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">业主信息</div>
            <div class="agree_detail_item">
              <div class="li w33">
                <span class="color-6">业主姓名：</span> {{ details.fang ? details.fang.yezhu_name : '' }}
              </div>
              <div class="li w33">
                <span class="color-6"> 电话：</span>{{ details.fang ? details.fang.yezhu_mobile : '' }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="flex flex-between">
              <div class="agree_detail_tit" style="margin-bottom: 0;">合同信息</div>
              <div class="report-more" @click="openDocument(5)">点击查看>></div>
            </div>
          </div>
          <div class="agree_detail_box">
            <div class="agree_detail_tit">合同附属信息</div>
            <div class="flexs agree_detail_list">
              <div class="m-r-10 agree_detail_li" @click="openDocument(1)">
                风险告知书
              </div>
              <div class="m-r-10 agree_detail_li" @click="openDocument(2)">
                房屋交接清单
              </div>
              <div class="m-r-10 agree_detail_li" @click="openDocument(3)">
                佣金确认书房东
              </div>
              <div class="agree_detail_li" @click="openDocument(4)">
                佣金确认书租客
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">成交报告</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">合同编号：</span>{{ details.contract_number }}
              </div>
              <div class="li w50">
                <span class="color-6">店铺：</span>{{ details.fang ? details.fang.dian_name : '' }}
              </div>
              <div class="li w50">
                <span class="color-6">成交价格：</span>{{ details.price }}元
              </div>
              <div class="li w50">
                <span class="color-6">收佣：</span>{{ details.yong_price }}元
              </div>
              <div class="li w50">
                <span class="color-6">组别：</span>{{ details.zubie }}
              </div>
              <div class="li w50">
                <span class="color-6">喜报单数：</span>{{ details.xibao }}
              </div>
              <div class="li w50" style="word-wrap:break-word;word-break:break-all;">
                <span class="color-6">备注：</span>{{ details.beizhu }}
              </div>
              <div class="li w50">
                <span class="color-6">资料是否齐全：</span>{{ details.ziliao }}
              </div>
            </div>
            <div class="agree_detail_item">
              <img class="img" v-for="(item, index) in details.ziliao_image" :key="index" :src="item" />
            </div>
            <div class="agree_detail_item" v-for="(item, index) in details.chengjiao" :key="index + 'chengjiao'">
              <div class="li w33">
                <span class="color-6">成交方：</span>{{ item.user_name }}
              </div>
              <div class="li w33">
                <span class="color-6">比例：</span>{{ item.bi }}%
              </div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item, index) in details.xinxi" :key="index + 'xinxi'">
              <div class="li w33">
                <span class="color-6">信息方：</span>{{ item.user_name }}
              </div>
              <div class="li w33">
                <span class="color-6">比例：</span>{{ item.bi }}%
              </div>
              <div class="li w33">
                <span class="color-6">信息方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item, index) in details.weihu" :key="index + 'weihu'">
              <div class="li w33">
                <span class="color-6">维护方：</span>{{ item.user_name }}
              </div>
              <div class="li w33">
                <span class="color-6">比例：</span>{{ item.bi }}%
              </div>
              <div class="li w33">
                <span class="color-6">维护方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item, index) in details.img" :key="index + 'img'">
              <div class="li w33">
                <span class="color-6">图片方：</span>{{ item.user_name }}
              </div>
              <div class="li w33">
                <span class="color-6">比例：</span>{{ item.bi }}%
              </div>
              <div class="li w33">
                <span class="color-6">图片方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item, index) in details.yaoshi" :key="index + 'yaoshi'">
              <div class="li w33">
                <span class="color-6">钥匙方：</span>{{ item.user_name }}
              </div>
              <div class="li w33">
                <span class="color-6">比例：</span>{{ item.bi }}%
              </div>
              <div class="li w33">
                <span class="color-6">钥匙方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item, index) in details.weituo" :key="index + 'weituo'">
              <div class="li w33">
                <span class="color-6">委托方：</span>{{ item.user_name }}
              </div>
              <div class="li w33">
                <span class="color-6">比例：</span>{{ item.bi }}%
              </div>
              <div class="li w33">
                <span class="color-6">委托方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item, index) in details.qita" :key="index + 'qita'">
              <div class="li w33">
                <span class="color-6">其他方：</span>{{ item.user_name }}
              </div>
              <div class="li w33">
                <span class="color-6">比例：</span>{{ item.bi }}%
              </div>
              <div class="li w33">
                <span class="color-6">其他方业绩：</span>{{ item.price }}元
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">订单信息</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">提交时间：</span>{{ details.createtime }}
              </div>
              <template v-if="details.status == 2">
                <div class="li w50">
                  <span class="color-6">失败时间：</span>{{ details.updatetime }}
                </div>
                
                <div class="li w50">
                  <span class="color-6">失败原因：</span>{{ details.bo_text }}
                </div>
              </template>
            </div>
          </div>
          <!--agree_detail_box end-->
        </div>
        <!--agree_detail end-->
      </div>
    </div>
    <!--拒绝弹框 S -->
    <audit-pop-re ref="re" @succend="succend"></audit-pop-re>
    <!-- 拒绝弹框 E -->
    <sign-pop ref="report"></sign-pop>
  </div>
</template>

<script>
import AuditPopRe from "../../components/AuditPopRe";
import signPop from "../../components/SignPop.vue"
export default {
  components: { AuditPopRe,signPop },
  data() {
    return {
      id: null, //
      status: null, //
      type: null, //
      details: {}, //详情
    };
  },
  methods: {
    openDocument(index) {
      let url = "";
      if (index == 1) {
        url = this.details.fengxian_id_file;
      } else if (index == 2) {
        url = this.details.fang_jiao_id_file;
      } else if (index == 3) {
        url = this.details.jin_chu_id_file;
      } else if (index == 4) {
        url = this.details.jin_zu_id_file;
      } else if (index == 5) {
        url = this.details.contract_image[0];
      }
      window.open(
        "https://view.officeapps.live.com/op/view.aspx?src=" +
        encodeURIComponent(url)
      );
    },
    succend() {
      setTimeout(() => {
        this.$router.go(-1);
      }, 800);
    },
    //审核
    changeCheck(index) {
      if (index == 1) {
        this.$refs.re.open(this.id, 2);
      } else if (index == 2) {
        this.$axios.baoGaoDealT({ id: this.id }).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.$router.go(-1);
          }, 800);
        });
      } else if (index == 3) {
        this.$refs.report.open(this.details.xinxi_id, 2, this.details.fang.fang_type1)
      }
    },
    //取消
    cancelReport() {
      this.$axios.baoGaoDealQ({ id: this.id }).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 800);
      });
    },
    //获取详情
    getDetails() {
      this.$axios.baoGaoDealDetail({ id: this.id }).then((res) => {
        this.details = res.data;
      });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    this.type = this.$route.query.type;
    this.getDetails();
  },
};
</script>

<style scoped lang="less">
.report-more {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.audit-list-btn {
  height: 40px;
  border-radius: 4px;
  padding: 0 24px;
  font-size: 16px;
  line-height: 40px;
  border: none;
  outline: none;
  position: relative;
  z-index: 11;
}

.audit-list-btn.blue {
  background: #3273f6;
  color: #fff;
}

.audit-list-btn.red {
  background: #fddfdf;
  color: #ff5151;
}

.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;

  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }

  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }

  .select {
    width: 200px;
  }

  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}

.agree_a {
  color: #3273f6;
  cursor: pointer;
}

/deep/.key_page_select .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}

.agree_main {
  background: #fff;
  padding-bottom: 30px;
}

.agree-head {
  margin: 0 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #eaeaea;
}

.agree_pos {
  padding: 30px 30px;
  color: #666;
}

.agree_detail {
  background: #f8fafe;
  margin: 0 50px;
  padding: 10px 30px 10px 30px;

  .agree_detail_tit {
    font-size: 18px;
    position: relative;
    padding-left: 10px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .agree_detail_tit:before {
    width: 4px;
    height: 20px;
    background: #3273f6;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -10px;
    display: block;
    content: "";
  }

  .agree_detail_item {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding-left: 10px;
  }

  .w33 {
    width: 30%;
  }

  .w50 {
    width: 40%;
  }

  .li {
    line-height: 30px;
  }

  .agree_detail_box {
    margin: 25px 0px;
  }

  .img {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}

.personage_ul_tags {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}

.personage_ul {
  .t-flex {
    display: flex;

    .t-tag {
      background: #e1ebff;
      border-radius: 3px;
      line-height: 28px;
      padding: 0px 10px;
      font-size: 14px;
      color: #3273f6;
    }
  }

  li {
    padding: 0px 50px 30px 50px;
    display: flex;

    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }

      position: relative;

      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }

    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
      }

      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }

      .personage_ul_price {
        display: flex;
        align-items: flex-end;

        .personage_ul_price_left {
          display: flex;
          align-items: center;

          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }

        .personage_ul_price_right {
          display: flex;
          align-items: center;

          span {
            color: #999999;
            font-size: 18px;
          }
        }

        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }

      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;

        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }

        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }

          margin-right: 10px;
        }

        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }

        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;

            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}

.agree_detail_list {
  .agree_detail_li {
    width: 121px;
    height: 33px;
    color: #3273f6;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0253f7;
  }
}
</style>
